import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly httpService: HttpService) { }

  getUserData(): Observable<any> {
    return this.httpService.request('me').get(environment.apiConfig.uri);
  }
}
