import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HTTP_STATUS_CODES } from '@bbraun/cortex/shared';
import { catchError, Observable, throwError } from 'rxjs';

import { ROUTES } from '../constants/routes/routes.constants';

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
        case HTTP_STATUS_CODES.NOT_FOUND: {
          this.router.navigate([ ROUTES.ERROR.ERROR, ROUTES.ERROR.NOT_FOUND ]);
          break;
        }
        case HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR: {
          this.router.navigate([ ROUTES.ERROR.ERROR, ROUTES.ERROR.SERVER_ERROR ]);
          break;
        }
        case HTTP_STATUS_CODES.FORBIDDEN:
        case HTTP_STATUS_CODES.UNAUTHORIZED: {
          this.router.navigate([ ROUTES.ERROR.ERROR, ROUTES.ERROR.NOT_AUTHORIZED ]);
          break;
        }
        default: {
          this.router.navigate([ ROUTES.ERROR.ERROR, ROUTES.ERROR.NOT_FOUND ]);
          break;
        }
        }
        return throwError(() => error);
      })
    );
  }
}
