import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(_: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(
    _: ActivatedRouteSnapshot,
    __: DetachedRouteHandle
  ): void {}
  shouldAttach(_: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(_: ActivatedRouteSnapshot): DetachedRouteHandle {
    return null;
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return curr.routeConfig === null && future.routeConfig === null;
  }
}
