export const DEV_CLIENT_ID = '18f0cae7-e7fb-421e-82db-b15367c41bf6';
export const QAS_CLIENT_ID = 'e36d9fd5-1c0d-4f37-b1f8-2737fee6fdec';
export const PROD_CLIENT_ID = 'fd24c442-90e2-4675-937d-0eff25119366';

export const QAS_SCOPES = [
  'openid',
  'profile',
  'offline_access',
  'https://test.account.bbraun.com/auth/gpr/User.Read',
];

export const QAS_URI = 'https://api.myprofile.test.account.bbraun.com/api';

export const QAS_ISSUER_URL = 'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/';

export const QAS_AUTHORITY_DOMAIN = 'test.account.bbraun.com';

export const PROD_SCOPES = [
  'openid',
  'profile',
  'offline_access',
  'https://account.bbraun.com/auth/gpr/User.Read',
];

export const PROD_URI = 'https://api.myprofile.account.bbraun.com/api';

export const PROD_ISSUER_URL = 'https://account.bbraun.com/1fa01227-ce45-482e-a1dc-3bfd547280ef/B2C_1A__SIGNUP_SIGNIN_MFA/';

export const PROD_AUTHORITY_DOMAIN = 'account.bbraun.com';

export const DEV_BE_SCOPE = [ 'https://test.account.bbraun.com/cfc98a98-1152-4c11-ab2f-c28109870788/read' ];
export const QAS_BE_SCOPE = [ 'https://test.account.bbraun.com/9c0370dc-d1d0-4a5b-9b87-6ba2449ba66e/read' ];
export const PROD_BE_SCOPE = [ 'https://account.bbraun.com/4290fe1f-b6d9-4fa6-adf2-7e8717a02f04/read' ];

export const QAS_PROFILE_URL = 'https://myprofile.test.account.bbraun.com';
export const PROD_PROFILE_URL = 'https://myprofile.account.bbraun.com';
