import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard, MsalGuardConfiguration,
  MsalInterceptor, MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { SNACKBAR_CONFIG } from '@bbraun/cortex/snackbar';
import { HttpService } from '@bbraun/helpex/src/lib/http';
import { StoreModule } from '@ngrx/store';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CustomRouteReuseStrategy } from './custom-route-reuses-strategy';
import { DATE_FORMAT } from './modules/shared/constants/date-format/date-format.constant';
import { GeneralInterceptor } from './modules/shared/interseptors/general-interceptor.service';
import { UserService } from './modules/shared/service/user/user.service';
import {
  loginRequest,
  msalConfig
} from './modules/shared/utilities/config/auth-config';
import { TranslocoRootModule } from './transloco-root.module';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit :
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  protectedResourceMap.set(
    environment.backendApiConfig.uri,
    environment.backendApiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    StoreModule.forRoot({
    }, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
      }
    }),
    // StoreDevtoolsModule.instrument({ maxAge: 25 }),
    MatDatepickerModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: SNACKBAR_CONFIG, useValue: {
        maxOpened: 5,
        closeButton: true
      }
    },
    CxMaterialConfigProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HttpService,
    UserService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
