import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

import { ROUTES } from './modules/shared/constants/routes/routes.constants';

const routes: Routes = [
  {
    path: ROUTES.HOME,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [ MsalGuard ]
  },
  {
    path: ROUTES.ERROR.ERROR,
    loadChildren: () => import('./modules/error-page/error-page.module').then((m) => m.ErrorPageModule),
    canActivate: [ MsalGuard ]
  },
  { path: '**', redirectTo: ROUTES.HOME, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, <any>{
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
