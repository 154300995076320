import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageHelperService {

  get(item: string): string {
    const result: string | null = localStorage.getItem(item);

    if (typeof result !== 'string') {
      return null;
    }

    return JSON.parse(result);
  }

  set(name: string, item: string): void {
    localStorage.setItem(name, JSON.stringify(item));
  }
}
