import {
  QAS_AUTHORITY_DOMAIN,
  QAS_BE_SCOPE,
  QAS_CLIENT_ID,
  QAS_ISSUER_URL,
  QAS_PROFILE_URL,
  QAS_SCOPES,
  QAS_URI
} from '../app/modules/shared/constants/auth.constants';

export const environment = {
  staging: true,
  environment: 'QAS',
  enableProdMode: true,
  backendUrl: 'https://ucid.qas.bbraun.cloud/backend',
  // instana config
  trackingUi: {
    enable: true,
    reportingUrl: '',
    apiKey: ''
  },
  msalConfig: {
    auth: {
      clientId: QAS_CLIENT_ID
    }
  },
  apiConfig: {
    scopes: QAS_SCOPES,
    uri: QAS_URI
  },
  backendApiConfig: {
    scopes: QAS_BE_SCOPE,
    uri: 'https://ucid.qas.bbraun.cloud/backend'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A__SIGNUP_SIGNIN_MFA',
    },
    authorities: {
      signUpSignIn: {
        authority: QAS_ISSUER_URL
      },
    },
    authorityDomain: QAS_AUTHORITY_DOMAIN
  },
  profile: QAS_PROFILE_URL
};
