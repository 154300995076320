import { Component, ViewContainerRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';

import { OAuthService } from './modules/shared/service/oAuth/oAuth.service';
import { TranslationService } from './modules/shared/service/translation/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.sass' ]
})

export class AppComponent {

  constructor(
    private readonly oauthService: OAuthService,
    private readonly translationService: TranslationService,
    private readonly snackbarService: CxSnackbarService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly registry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    this.snackbarService.setViewContainerRef(viewContainerRef);
    this.translationService.init();
    this.oauthService.configureAuth();
    this.registry.addSvgIcon('organisation_menu',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/organisation_menu_white_24dp.svg')
    );
  }

}
