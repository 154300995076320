import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';

import { tapResponse } from '@ngrx/component-store';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, pipe } from 'rxjs';

import { ROUTES } from '../../modules/shared/constants/routes/routes.constants';
import { User, UserOrganization } from '../../modules/shared/interfaces/user/user.interface';
import { LocalStorageHelperService } from '../../modules/shared/service/local-storage-helper/local-storage-helper.service';
import { UserService } from '../../modules/shared/service/user/user.service';

interface UserState {
  currentUser: User | undefined;
  selectedOrganization: UserOrganization | undefined;
  organizationsBannerVisible: boolean;
}

const initialState: UserState = {
  currentUser: undefined,
  selectedOrganization: undefined,
  organizationsBannerVisible: true
};

export const UserStateService = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(store => {
    return {
      isBasicUserDataLoaded: computed(() => !!store.currentUser()),
      isUserLoaded: computed(() => !!store.currentUser() && !!store.selectedOrganization()),
      organizationName: computed(() => store.selectedOrganization()?.name ?? ''),
      organizationId: computed(() => store.selectedOrganization()?.id),
      isMultiOrgUser: computed(() => (store.currentUser() && store.currentUser().sites?.length > 1)),
      userOrganizations: computed(() => store.currentUser()?.sites ?? []),
      organizationSapId: computed(() => store.selectedOrganization()?.sapId ?? '')
    };
  }),
  withMethods(store => {
    const userService = inject(UserService);
    const localStorageHelper = inject(LocalStorageHelperService);
    const router = inject(Router);

    function navigateToErrorPage(): void {
      router.navigate([ ROUTES.ERROR.ERROR, ROUTES.ERROR.NOT_AUTHORIZED ]);
    }

    return {
      loadUserData: rxMethod<void>(pipe(
        concatMap(() => userService.getUserData().pipe(
          tapResponse(
            (response: User) => {
              if (response.sites?.length === 0) {
                patchState(store, { currentUser: response });
                navigateToErrorPage();
              } else {
                let selectedOrganization = response.sites[0];
                const foundLocalstorageData = localStorageHelper.get(response.id);
                const savedOrganizationData = JSON.parse(foundLocalstorageData !== '' ? foundLocalstorageData : null);
                // eslint-disable-next-line no-prototype-builtins
                if (savedOrganizationData && savedOrganizationData.hasOwnProperty('selectedOrganization')) {
                  const foundOrganization = response.sites.find(
                    (site: UserOrganization) => site.id === savedOrganizationData['selectedOrganization']);
                  if (foundOrganization) {
                    selectedOrganization = foundOrganization;
                  }
                }
                patchState(store, { currentUser: response, selectedOrganization: selectedOrganization });
              }
            },
            () => {}
          )
        ))
      ))
    };
  }),
  withMethods(store => {
    const localStorageHelper = inject(LocalStorageHelperService);
    return {
      setSelectedOrganization(organization: UserOrganization): void {
        localStorageHelper.set(store.currentUser().id, JSON.stringify({ selectedOrganization: organization.id }));
        patchState(store, { selectedOrganization: organization });
      },
      setOrganizationBannerVisibility(isVisible: boolean): void {
        patchState(store, { organizationsBannerVisible: isVisible });
      }
    };
  })
);
